export const homeObjectOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  topLine: "Software Resources",
  headLine: "Software Engineers for Your Projects",
  description: "Infinate Blu provides contract Engineering resource for your projects. They can be remote or on premis",
  buttonLabel: "More...",
  buttonUrl: "/",
  imgStart: false,
  img: require("../../images/undraw_Source_code_re_wd9m.svg").default,
  alt: "Software Resources",
  dark: true,
  primary: true,
  darkText: false,
}

export const homeObjectTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDescription: false,
  topLine: "Project Management",
  headLine: "Delivering Fully Engineered Solutions",
  description: "Let us manage your projects, from concept through to deployment and beyond",
  buttonLabel: "More...",
  buttonUrl: "/",
  imgStart: true,
  img: require("../../images/undraw_Project_completed_re_pqqq.svg").default,
  alt: "Project Management",
  dark: false,
  primary: false,
  darkText: true,
}

export const homeObjectThree = {
  id: "services",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  topLine: "Dev Ops",
  headLine: "Full Cloud Architure",
  description: "We design, develop and deploy complete cloud and IoT infrustructure",
  buttonLabel: "More",
  buttonUrl: "/",
  imgStart: false,
  img: require("../../images/undraw_server_cluster_jwwq.svg").default,
  alt: "Dev Ops",
  dark: true,
  primary: true,
  darkText: false,
}
