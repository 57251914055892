import React, { useState, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import Home from "./Home"
import About from "./About"
import LandingPage from "../landingPages/contractorLandingPageTemplate"

const Main = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleSideBar = () => {
    setIsOpen(!isOpen)
  }

  const webSitePages = [
    { routePath: "/", title: "Home" },
    { routePath: "/About", title: "About Us" },
  ]

  const location = useLocation()
  const pathname = location.pathname

  const isNotLandingPage = () => {
    if (webSitePages.find(page => page.routePath == pathname) === undefined) return false
    else return true
  }

  return (
    <>
      {isNotLandingPage() ? (
        <>
          <Sidebar isOpen={isOpen} toggleSideBar={toggleSideBar} pages={webSitePages} />
          <Navbar toggleSideBar={toggleSideBar} pages={webSitePages} />
        </>
      ) : null}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/LandingPage" element={<LandingPage />} />
      </Routes>
    </>
  )
}

export default Main
