import React, { useState, useEffect } from "react"
import HeroSection from "../components/HeroSection"
import InfoSection from "../components/ButtonInfoSection"
import { homeObjectOne, homeObjectTwo, homeObjectThree } from "../components/ButtonInfoSection/Data"

const Home = () => {
  return (
    <>
      <HeroSection />
      <InfoSection {...homeObjectOne} />
      <InfoSection {...homeObjectTwo} />
      <InfoSection {...homeObjectThree} />
    </>
  )
}

export default Home
