export const aboutObjectOne = {
  id: "Infinate Blu",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  topLine: "Who are we...",
  headLine: "Our History",
  description: `
Headquartered in Hong Kong with operations in China and the UK.
Infinate Blu Limited was formed in July 2014 by a merger between Telergy Hong Kong Limited and EightZero Digital Ltd.  Telergy HK and EightZero Digital both had extensive experience in IPTV and the merger of two companies enabled Infinate Blu Limited to provide one stop end to end IPTV solutions to the IPTV and Over-the-Top TV markets.
Telergy Hong Kong limited was founded in 2009 and established in Shenzhen China.  This company designed, developed, manufactured, and sold IPTV Set Top Boxes to global markets.  
EightZero Digital Ltd.  was formed in June 2010 after the sale of GDBTV. EightZero Digital created a Cloud based IPTV middleware platforms for Over-the-Top of the internet TV service providers.  The IPTV middleware solution provides customer management, live streaming, catch up TV, Video-on-Demand, and channel management along user applications for Set Top Boxes, Web and mobile.  These solutions have been deployed to international markets in Turkey, USA, Canada, and the UK.
Since 2018, Infinate Blu Limited, has also been using its extensive experience in Cloud systems development and embedded software engineering, to provide system architecture, software engineering and project management consultancy services, to companies around the world. 
  `,
  buttonLabel: "More",
  imgStart: false,
  img: require("../../images/pexels-nothing-ahead-3721597-2.jpg"),
  alt: "Dev Ops",
  dark: true,
  primary: true,
  darkText: false,
}

export const aboutDavidOne = {
  id: "David",
  lightBg: true,
  lightText: false,
  lightTextDescription: false,
  topLine: "Co-founder and CEO",
  headLine: "David Wray",
  description: `David is an early pioneer of over-the-top IPTV, has over two decades of experience in cloud technology and commercial business development. His vision and management skills led to recognition by UK Prime Minister, Gordon Brown in 2008, paving the way for IPTV use in education. David’s understanding of content acquisition and distribution was the foundation for the successful launch of PlayTV, the first over-the-top IPTV service in the UK, featuring Setanta Sports, Paramount Pictures, MTV, Al Jazeera and many other premium and multicultural channels.`,
  buttonLabel: "More",
  imgStart: true,
  img: require("../../images/David_Wray.jpg"),
  alt: "David Wray",
  dark: false,
  primary: false,
  darkText: true,
}

export const aboutTonyOne = {
  id: "Infinate Blu",
  lightBg: false,
  lightText: true,
  lightTextDescription: true,
  topLine: "Co-founder, COO & CTO",
  headLine: "Tony Haines",
  description: `
Tony is an expert in consumer electronics and software engineering and has over 30 Years of engineering development and operational management experience. Previously, Tony established a two Design and Operations offices in Shenzhen China, which deployed products to all corners of the globe.  Tony is Infinate Blu’s lead architect.

Chief Operating Officer & Chief Tech. Officer - Telergy N.V. (Shenzhen, China) 
Chief Technical Officer - CIMA Technology Co., Ltd. (Shenzhen, China) 
VP International Business Dev. - iPanel Technologies Ltd. (Shenzhen, China)
  `,
  buttonLabel: "More",
  imgStart: false,
  img: require("../../images/Tony_Haines.jpg"),
  alt: "Dev Ops",
  dark: true,
  primary: true,
  darkText: false,
}
