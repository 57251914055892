import React from "react"
import LandingPageSection from "../components/LandingPageSection"
import {
  ContractorLandingPageOne,
  ReferenceOne,
  ReferenceTwo,
  ReferenceThree,
  ReferenceFour,
  ReferenceFive,
} from "../components/LandingPageSection/Data"
import {
  ReferenceContainer,
  ReferenceWrapper,
  ReferenceItemContainer,
  ReferenceItemImage,
  ReferenceItemImageWrap,
  ReferenceItemText,
  ReferenceItemName,
  ReferenceItemJobTitle,
} from "../components/LandingPageSection/InfoElements"

import { useLocation } from "react-router-dom"
import { Nav, NavbarContainer, NavLogo, NavLogoBlu } from "../components/Navbar/NavbarElements"

const LandingPage = () => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to={pathname}>
            Infinate<NavLogoBlu>Blu</NavLogoBlu>
          </NavLogo>
        </NavbarContainer>
      </Nav>
      <LandingPageSection {...ContractorLandingPageOne} />
      <ReferenceContainer lightBg={ContractorLandingPageOne.lightBg} darkText={ContractorLandingPageOne.darkText}>
        <ReferenceWrapper>
          <ReferenceItemContainer lightBg={ContractorLandingPageOne.lightBg}>
            <ReferenceItemImageWrap>
              <ReferenceItemImage src={ReferenceOne.img} alt={ReferenceOne.alt} />
            </ReferenceItemImageWrap>
            <ReferenceItemText>"{ReferenceOne.description}"</ReferenceItemText>
            <ReferenceItemName>{ReferenceOne.name}</ReferenceItemName>
            <ReferenceItemJobTitle>{ReferenceOne.title}</ReferenceItemJobTitle>
          </ReferenceItemContainer>
          <ReferenceItemContainer lightBg={ContractorLandingPageOne.lightBg}>
            <ReferenceItemImageWrap>
              <ReferenceItemImage src={ReferenceTwo.img} alt={ReferenceTwo.alt} />
            </ReferenceItemImageWrap>
            <ReferenceItemText>"{ReferenceTwo.description}"</ReferenceItemText>
            <ReferenceItemName>{ReferenceTwo.name}</ReferenceItemName>
            <ReferenceItemJobTitle>{ReferenceTwo.title}</ReferenceItemJobTitle>
          </ReferenceItemContainer>
          <ReferenceItemContainer lightBg={ContractorLandingPageOne.lightBg}>
            <ReferenceItemImageWrap>
              <ReferenceItemImage src={ReferenceThree.img} alt={ReferenceThree.alt} />
            </ReferenceItemImageWrap>
            <ReferenceItemText>"{ReferenceThree.description}"</ReferenceItemText>
            <ReferenceItemName>{ReferenceThree.name}</ReferenceItemName>
            <ReferenceItemJobTitle>{ReferenceThree.title}</ReferenceItemJobTitle>
          </ReferenceItemContainer>
          <ReferenceItemContainer lightBg={ContractorLandingPageOne.lightBg}>
            <ReferenceItemImageWrap>
              <ReferenceItemImage src={ReferenceFour.img} alt={ReferenceFour.alt} />
            </ReferenceItemImageWrap>
            <ReferenceItemText>"{ReferenceFour.description}"</ReferenceItemText>
            <ReferenceItemName>{ReferenceFour.name}</ReferenceItemName>
            <ReferenceItemJobTitle>{ReferenceFour.title}</ReferenceItemJobTitle>
          </ReferenceItemContainer>
        </ReferenceWrapper>
      </ReferenceContainer>
    </>
  )
}
export default LandingPage
