import React from "react"
import { BrowserRouter } from "react-router-dom"
import styled from "styled-components"
import Main from "./pages"

const PageContainer = styled.div`
  font-family: "Encode Sans Expanded", sans-serif;
  @font-face {
    font-family: "Conv_vipnagorgialla";
    src: local("Conv_vipnagorgialla"), url(./fonts/vipnagorgialla.ttf) format("truetype");
  }
`

function App() {
  return (
    <BrowserRouter>
      <PageContainer>
        <Main />
      </PageContainer>
    </BrowserRouter>
  )
}
export default App
