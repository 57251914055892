import React from "react"
import { Button } from "../ButtonElement"
import { useNavigate } from "react-router-dom"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ButtonWrapper,
  ImageWrap,
  Image,
} from "./InfoElements"

const InfoSection = ({
  id,
  lightBg,
  imgStart,
  topLine,
  lightText,
  darkText,
  headLine,
  description,
  buttonLabel,
  alt,
  img,
  primary,
  dark,
  dark2,
  buttonUrl,
}) => {
  let navigate = useNavigate()

  return (
    <div>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <ButtonWrapper>
                  <Button
                    // to={buttonUrl}
                    onClick={() => {
                      navigate(buttonUrl)
                    }}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </ButtonWrapper>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImageWrap>
                <Image src={img} alt={alt} />
              </ImageWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </div>
  )
}

export default InfoSection
