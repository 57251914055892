export const ContractorLandingPageOne = {
  id: "ContractorLandingPageOne",
  lightBg: true,
  lightText: false,
  lightTextDescription: false,
  topLine: "Is IR35 taking your cash???",
  headLine: "Let us help you keep your money",
  description: "We can legally help you to run your business your way without the need to comply with IR35 regulations",
  buttonLabel: "Find Out How...",
  buttonUrl: "/",
  imgStart: true,
  img: require("../../images/undraw_wallet_aym5.svg").default,
  alt: "Project Management",
  dark: false,
  primary: false,
  darkText: true,
}

export const ReferenceOne = {
  description: "I have managed to get back all the money that I lost because of the IR35 laws",
  img: require("../../images/pexels-fauxels-3184611.jpg"),
  alt: "Backened Developer",
  name: "Jacob Thomas",
  title: "Node JS Backend Developer",
}
export const ReferenceTwo = {
  description: "I have been a contract embedded programmer for 35 years, now I can run my business as I always have.",
  img: require("../../images/pexels-marcus-aurelius-6787710.jpg"),
  alt: "Embedded Programmer",
  name: "Steven Cooper",
  title: "Embedded SW Engineer",
}
export const ReferenceThree = {
  description: "I work as a Digital Nomad, didnt understand anything about IR35, but glad that I found this.",
  img: require("../../images/pexels-andrea-piacquadio-3768911.jpg"),
  alt: "Digital Nomad",
  name: "Olivia Williams",
  title: "React JS Developer",
}
export const ReferenceFour = {
  description:
    "I have been contracting for 15 years, and like the freedon of managing my own business, the IR35 regulations were killing me.",
  img: require("../../images/pexels-andrea-piacquadio-3777952.jpg"),
  alt: "Full Stack Developer",
  name: "Charlie Evans",
  title: "Full Stack Developer",
}
// export const ContractorLandingPageOne = {
//   id: "ContractorLandingPageOne",
//   lightBg: false,
//   lightText: true,
//   lightTextDescription: true,
//   topLine: "Is IR35 taking your money???",
//   headLine: "Let us help you get that money back",
//   description:
//     "We can legally help you to run your business your way, and without needing to comply with the IR35 regulations",
//   buttonLabel: "Find Out How...",
//   buttonUrl: "/",
//   imgStart: true,
//   img: require("../../images/undraw_wallet_aym5.svg").default,
//   alt: "Project Management",
//   dark: true,
//   primary: true,
//   darkText: false,
// }
