import React from "react"
import { FaBars } from "react-icons/fa"
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoBlu,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavButton,
  NavButtonLink,
} from "./NavbarElements"

const Navbar = ({ toggleSideBar, pages }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            Infinate<NavLogoBlu>Blu</NavLogoBlu>
          </NavLogo>
          <MobileIcon onClick={toggleSideBar}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {pages.map(page => (
              <NavItem>
                <NavLinks to={`${page.routePath}`}>{`${page.title}`}</NavLinks>
              </NavItem>
            ))}
          </NavMenu>
          {/* <NavButton>
            <NavButtonLink to="/Singin">Sign In</NavButtonLink>
          </NavButton> */}
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
