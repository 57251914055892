import React from "react"
import InfoSection from "../components/InfoSection"
import { aboutObjectOne, aboutDavidOne, aboutTonyOne } from "../components/InfoSection/Data"

const About = () => {
  return (
    <>
      <InfoSection {...aboutObjectOne} />
      <InfoSection {...aboutDavidOne} />
      <InfoSection {...aboutTonyOne} />
    </>
  )
}

export default About
