import React, { useState } from "react"
import { Button } from "../ButtonElement"
import {
  HeroContainer,
  HeroBackGround,
  VideoBackGround,
  HeroButtonWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight,
} from "./HeroElements"
import Video from "../../videos/video.mp4"
const Hero = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id="home">
      <HeroBackGround>
        <VideoBackGround autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBackGround>
      <HeroContent>
        <HeroH1>Computer Engineering With Less Hassle</HeroH1>
        <HeroP>Quality cost-effective contract engineering without 'Big Government' bureaucracy</HeroP>
        <HeroButtonWrapper>
          <Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
            Start Here {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroButtonWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
