import React from "react"
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from "./SidebarElements"

const Sidebar = ({ isOpen, toggleSideBar, pages }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggleSideBar}>
      <Icon onClick={toggleSideBar}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {pages.map(page => (
            <SidebarLink to={`${page.routePath}`} onClick={toggleSideBar}>
              {`${page.title}`}
            </SidebarLink>
          ))}
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
